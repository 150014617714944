const dataSlider = [
    {
        id: 1,
        title: "image"
    },
    { 
         id: 2,
        title: "image2"
 
   },
   {
    id: 3,
    title: "image3"

},
{
    id: 4,
    title: "image4"

}


]

export default dataSlider;